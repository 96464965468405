import React from 'react';
import { Header, Icon, Tab, Button } from 'semantic-ui-react'

const panes = [
  { menuItem: 'Spieltage ', render: () => 
  <Tab.Pane>
    <br></br> 
    5 Spieltage<br></br> 
    Pro Spieltag werden 8 Spieler eingesetzt. <br></br>  
    Die besten 7 Scores zählen, das schlechteste Ergebnis fällt raus.<br></br> <br></br>  
    
    <Button color='olive' style={{backgroundColor: '#9fafa1'}} fluid as='a' href='/spieltage'><Icon name='users' />Siehe Spieltage.</Button>
  </Tab.Pane> },
  { menuItem: 'Liga', render: () => 
  <Tab.Pane>
    <br></br> 
    GVSH Verbandsliga (DGL-Gruppenliga) der Region Nord<br></br> <br></br> 
    
    <Button color='olive' style={{backgroundColor: '#9fafa1'}} fluid as='a' href='https://www.gvsh.de/sport/mannschaftswettbewerbe/erwachsene/tags/verbandsliga-region-nord.html' target='_blank'><Icon name='linkify' />GVSH Ergebnisse etc.</Button>
  </Tab.Pane> },
  { menuItem: 'Training ', render: () => 
  <Tab.Pane>
    <br></br> 
    Mittwochs 18:30
    <br></br> <br></br>
  </Tab.Pane> },
  { menuItem: 'Kasse', render: () => 
  <Tab.Pane>
    <br></br> 
    300 € p. P. / Jahr<br></br>
    Mannschaftskasse<br></br> <br></br> 

    PayPal: @SiekFlyers <br></br><br></br>
    <Button color='blue' fluid as='a' href='https://www.paypal.com/paypalme/SiekFlyers' target='_blank'><Icon name='paypal' />Zu PayPal</Button>
  </Tab.Pane> },
]

const InfoPage = () => {
  return (
    <div>
      <Header as='h1'>
        <Icon name='info' style={{color: '#9fafa1'}}/>
        <Header.Content>
          Info
          <Header.Subheader>Informationen & Wissenswertes</Header.Subheader>
        </Header.Content>
      </Header>
      <br></br>
      <Tab panes={panes} />
    </div>
  );
};

export default InfoPage;