import React from 'react';
import { Menu, Container, Image, Icon } from 'semantic-ui-react'
import { Route, Routes, Link } from 'react-router-dom';

import HomePage from './HomePage';
import InfoPage from './InfoPage';
import SpielerPage from './SpielerPage';
import SpieltagePage from './SpieltagePage';
import TuckerPage from './TuckerPage';

const GCS = () => {
    return (
        <div style={{ paddingTop: '50px' }}>

            <Menu inverted fixed='top'>
                <Menu.Item>
                    <Image as='a' size='tiny' src='/siek-flyers-logo.svg' href='/' />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item name='InfoPage'>
                        <Link to='/info'><Icon name='info' style={{color: '#9fafa1'}}/></Link>
                    </Menu.Item>
                    <Menu.Item name='SpielerPage'>
                        <Link to='/spieler'><Icon name='users' style={{color: '#9fafa1'}}/></Link>
                    </Menu.Item>
                    <Menu.Item name='SpieltagePage'>
                        <Link to='/spieltage'><Icon name='calendar alternate outline' style={{color: '#9fafa1'}}/></Link>
                    </Menu.Item>
                    <Menu.Item name='Tucker'>
                        <Link to='/tucker'><Icon name='trophy' style={{color: '#9fafa1'}}/></Link>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Container style={{ paddingTop: '5em' }}>
                <Routes>
                    <Route exact path='/' element={<HomePage />} />
                    <Route path='/info' element={<InfoPage />} />
                    <Route path='/spieler' element={<SpielerPage />} />
                    <Route path='/spieltage' element={<SpieltagePage />} />
                    <Route path='/tucker' element={<TuckerPage />} />
                </Routes>
            </Container>
        </div>
        
    );
};
  
export default GCS;