import React from 'react';
import { useState, useEffect } from 'react';
import { Header, Icon, Table, Input, Menu } from 'semantic-ui-react'

const TuckerPage = () => {

  const[score, setScore] = useState({});
  const[total, setTotal] = useState(0);

  const updateScore = (event) => {

    if (event.target.value > 5) {
      event.target.value = 5; 
    } else if(event.target.value < 0) {
      event.target.value = 0; 
    } else if(event.target.value.length > 1) {
      event.target.value = event.target.value.slice(0,1); 
    };;

    if (event.target.value) {
      setScore({...score, [event.target.name]: parseInt(event.target.value)});
    } else {
      setScore({...score, [event.target.name]: 0});
    }

  };

  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setTotal(Object.values([score][0]).reduce((t, v) => t + v, 0));
  });

  return (
    <div>
      <Header as='h1'>
        <Icon name='trophy' style={{color: '#9fafa1'}}/>
        <Header.Content>
          Tucker Test
          <Header.Subheader>Shortgame Handicap Calculator</Header.Subheader>
        </Header.Content>
      </Header>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Pitch (kurz)</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>15</Table.Cell>
            <Table.Cell>1,5m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p15'><input max='5' onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>20</Table.Cell>
            <Table.Cell>2,0m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p20'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Pitch (lang)</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>2,0m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p25'><input onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>40</Table.Cell>
            <Table.Cell>2,0m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p40'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>55</Table.Cell>
            <Table.Cell>3,0m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p55'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>70</Table.Cell>
            <Table.Cell>3,0m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p70'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>80</Table.Cell>
            <Table.Cell>3,0m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p80'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Bunker</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>6</Table.Cell>
            <Table.Cell>1,2m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='b6'><input onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>12</Table.Cell>
            <Table.Cell>1,8m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='b12'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>18</Table.Cell>
            <Table.Cell>2,4m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='b18'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Chip</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>12</Table.Cell>
            <Table.Cell>1,2m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='c12'><input onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>18</Table.Cell>
            <Table.Cell>1,8m</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='c18'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Trouble Shots</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>diverse</Table.Cell>
            <Table.Cell>15%</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='ts'><input onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Putt (kurz)</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>1,0</Table.Cell>
            <Table.Cell>gelocht</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p10'><input onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1,5</Table.Cell>
            <Table.Cell>gelocht</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p15'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2,0</Table.Cell>
            <Table.Cell>gelocht</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p20'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2,5</Table.Cell>
            <Table.Cell>gelocht</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p25'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3,0</Table.Cell>
            <Table.Cell>gelocht</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p30'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'><Header as='h3'>Putt (lang)</Header></Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Entfernung</Table.HeaderCell>
            <Table.HeaderCell>Punkte bei</Table.HeaderCell>
            <Table.HeaderCell>Punkte</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>6</Table.Cell>
            <Table.Cell>Si-Zone</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p6'><input onChange={updateScore}/> /5</Input></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>12</Table.Cell>
            <Table.Cell>S-Länge</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p12'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>18</Table.Cell>
            <Table.Cell>S-Länge</Table.Cell>
            <Table.Cell><Input fluid transparent placeholder='Punkte' type='number' name='p18'><input onChange={updateScore}/> /5</Input>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br></br>
      <Menu inverted fixed='bottom'>
      <Table inverted unstackable basic='very' style={{padding: '10px 20px'}}>
          <Table.Body>
            <Table.Row>
              <Table.Cell><Header inverted as='h3'>Gesamtpunkte</Header></Table.Cell>
              <Table.Cell><Header inverted as='h2' textAlign='right'>{ total } /100</Header></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Menu>
      <br></br><br></br><br></br><br></br>
    </div>
  );
};

export default TuckerPage;