import _ from 'lodash'
import React from 'react';
import { Header, Icon, Table } from 'semantic-ui-react'

const tableData = [
  { name: 'Robin Trepte', hcpi: 4.0, size: 'S'},
  { name: 'Bo Schimmelpfeng', hcpi: 7.6, size: 'XL'},
  { name: 'Christian Schur', hcpi: 11.0, size: 'L'},
  { name: 'Niklas Krack', hcpi: 13.2, size: 'L'},
  { name: 'Daniel Emmerich', hcpi: 14.0, size: 'L'},
  { name: 'Tobias Prautsch', hcpi: 17.6, size: 'XL'},
  { name: 'Kenneth Rost', hcpi: 19.6, size: 'L'},
  { name: 'Finn Rost', hcpi: 19.7, size: 'L'},
  { name: 'Les Stewart', hcpi: 26.3, size: 'L'},
  { name: 'Ian Korthals', hcpi: 26.5, size: 'M'},
  { name: 'Johann Frey', hcpi: 27.1, size: 'L'},
  { name: 'Tom Carruthers', hcpi: 32.6, size: 'M'}
]


function exampleReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        }
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: 'ascending',
      }
    default:
      throw new Error()
  }
}

const SpielerPage = () => {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: tableData,
    direction: null,
  })
  const { column, data, direction } = state
  return (
    <div>
      <Header as='h1'>
        <Icon name='users' style={{color: '#9fafa1'}}/>
        <Header.Content>
          Spieler
          <Header.Subheader>Angemeldete Mannschaftsspieler</Header.Subheader>
        </Header.Content>
      </Header>
      <br></br>
    <Table unstackable sortable celled fixed basic='very' striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === 'name' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'hcpi' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'hcpi' })}
          >
            HCPI (Stand: Saisoneröffnung 2023)
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'size' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'size' })}
          >
            Größe
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map(({ name, hcpi, size }) => (
          <Table.Row key={name}>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{hcpi}</Table.Cell>
            <Table.Cell>{size}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
    </div>
  );
};

export default SpielerPage;