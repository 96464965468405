import React from 'react';
import { Header, Icon, Table, Button } from 'semantic-ui-react'

const SpieltagePage = () => {
  return (
    <div>
      <Header as='h1'>
        <Icon name='calendar' style={{color: '#9fafa1'}}/>
        <Header.Content>
          Spieltage
          <Header.Subheader>Verbandsligaspiele 2023</Header.Subheader>
        </Header.Content>
      </Header>
      <br></br>
      <Table basic='very' striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Spieltag</Table.HeaderCell>
            <Table.HeaderCell>Datum</Table.HeaderCell>
            <Table.HeaderCell>Gastgeber</Table.HeaderCell>
            <Table.HeaderCell>Aufstellung</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>1</Table.Cell>
            <Table.Cell collapsing>14.05.2023</Table.Cell>
            <Table.Cell>GC Hoisdorf</Table.Cell>
            <Table.Cell>Robin, Kenneth, Tobi, Daniel, Bo, Finn, Christian, Les</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>2</Table.Cell>
            <Table.Cell collapsing>28.05.2023</Table.Cell>
            <Table.Cell>GC Siek/Ahrensburg</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>3</Table.Cell>
            <Table.Cell collapsing>11.06.2023</Table.Cell>
            <Table.Cell>G&CC Brunstorf 2</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>4</Table.Cell>
            <Table.Cell collapsing>25.06.2023</Table.Cell>
            <Table.Cell>GC Segeberg</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>5</Table.Cell>
            <Table.Cell collapsing>23.07.2023</Table.Cell>
            <Table.Cell>GC Gut Grambek</Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button color='olive' style={{backgroundColor: '#9fafa1'}} fluid as='a' href='https://gvsh.de/files/Dokumente/2023/Verbandsliga/Einteilung%20Verbandsliga%20Herren%202023%20korrigiert.pdf' target='_blank'><Icon name='linkify' />GVSH Einteilung</Button>
    </div>
  );
};

export default SpieltagePage;