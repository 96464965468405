import React from 'react';
import { Header, Icon, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div>
        <Header as='h1'>
            <Icon name='home' style={{color: '#9fafa1'}}/>
            <Header.Content>
                Siek Flyers
                <Header.Subheader>GC Siek Herrenmannschaft</Header.Subheader>
            </Header.Content>
        </Header>
        <br></br>
        <Grid stackable doubling>
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Link to='/info'>
                        <Header as='h2' icon block style={{ padding: '40px', width: '100%' }}>
                            <Icon name='info' style={{color: '#9fafa1'}}/><br></br>
                            Infos
                            <Header.Subheader>
                            Informationen & Wissenswertes
                            </Header.Subheader>
                        </Header>
                    </Link>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Link to='/spieler'>
                        <Header as='h2' icon block style={{ padding: '40px', width: '100%' }}>
                            <Icon name='users' style={{color: '#9fafa1'}}/><br></br>
                            Spieler
                            <Header.Subheader>
                            Angemeldete Mannschaftsspieler
                            </Header.Subheader>
                        </Header>
                    </Link>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Link to='/spieltage'>
                        <Header as='h2' icon block style={{ padding: '40px', width: '100%' }}>
                            <Icon name='calendar' style={{color: '#9fafa1'}}/><br></br>
                            Spieltage
                            <Header.Subheader>
                            Verbandsligaspieltage 2023
                            </Header.Subheader>
                        </Header>
                    </Link>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    <Link to='/tucker'>
                        <Header as='h2' icon block style={{ padding: '40px', width: '100%' }}>
                            <Icon name='trophy' style={{color: '#9fafa1'}}/><br></br>
                            Tucker Test
                            <Header.Subheader>
                            Short Game Handicap Calculator
                            </Header.Subheader>
                        </Header>
                    </Link>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
  );
};

export default HomePage;