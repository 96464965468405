import { BrowserRouter } from 'react-router-dom';
import GCS from './components/GCS';

function App() {
  return (
    <BrowserRouter>
      <GCS />
    </BrowserRouter>
  );
}

export default App;
